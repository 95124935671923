import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AuthenticationService } from '../../../core/services/auth.service';
import { AuthfakeauthenticationService } from '../../../core/services/authfake.service';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { ActivatedRoute, Router } from '@angular/router';
import { first, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { UserProfileService } from 'src/app/core/services/user.service';
import { LogsService } from '../../../core/services/logs.service';
import  { detectBrowserName,Decrypt,Encrypt }  from './../../../pages/functions/functions';
import Swal from 'sweetalert2';
import { SettingsService } from '../../../core/services/settings.service';
import { EventosService } from '../../../core/services/eventos.service';
import * as moment from 'moment';
@Component({
  selector: 'app-login2',
  templateUrl: './login2.component.html',
  styleUrls: ['./login2.component.scss']
})
/**
 * Login-2 component
 */
export class Login2Component implements OnInit {
  cargando:boolean = true;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router,
    private userService:UserProfileService,
    private logService:LogsService,
    private settingsService:SettingsService,
    private eventosService:EventosService) {
      // window.location.reload()
      // this.getSettings();
     }
  loginForm: FormGroup;
  logo_img;
  submitted = false;
  error = '';
  showerror:boolean = false;
  returnUrl: string;
  isLoading = false;
  button = 'Iniciar Sesión';
  fieldTextType: boolean;
  session;
  ubicaciones: any;
  // set the currenr year
  year: number = new Date().getFullYear();

  ngOnInit(): void {
    // this.cargando = false;
    this.getUbicaciones();
    this.getSettings();
    let token = localStorage.getItem('token');
    if(token){
      this.userService.validarToken().subscribe(estaAutenticado =>  {

        if (estaAutenticado ) {
              this.router.navigateByUrl('/dashboard');
            }else{
              this.router.navigateByUrl('/account/login');

            }
         
          });



    }else{
      this.cargando = false;
    }
    document.body.classList.add('auth-body-bg')
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
      ubicacion:[localStorage.getItem('table') || null,[Validators.required]]
    });

    // admin@qualtia.com
    // Qualtia@1122
    // reset login status
    // this.authenticationService.logout();
    // get return url from route parameters or default to '/'
    // tslint:disable-next-line: no-string-literal
    // this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  carouselOption: OwlOptions = {
    items: 1,
    loop: false,
    margin: 0,
    nav: false,
    dots: true,
    responsive: {
      680: {
        items: 1
      },
    }
  }

   // convenience getter for easy access to form fields
   get f() { return this.loginForm.controls; }

   /**
    * Form submit
    */
   async onSubmit() {
     let geoip:any =  await this.userService.geoip;
     let publicip:any = await this.userService.publicip;
     this.submitted = true;
       if(this.loginForm.invalid){
         return;
       }
 
       let is_valid:any = await this.validSession();
       if(!is_valid.ok){
        Swal.fire('Error', is_valid.msg , 'error');
         return;
       }
 
 
 
       this.isLoading = true;
       this.button = 'Cargando';
 
       this.userService.login(this.loginForm.value).subscribe(async(resp:any) =>{
 
       if(resp.ok){
 
       
       if(resp.email_exist.is_new  && this.session == '1' ){
           
           this.userService.generateToken(resp.token).subscribe((resp1:any) =>{
             this.router.navigate(['/account/password-reset',resp1.token])
           })
 
         return;
       }
       this.router.navigate(['/dashboard']);
       let id = resp.email_exist.id;
 
        localStorage.setItem('key_id', Encrypt(id.toString()));
 
         // console.log(;
         let msg;
         if(resp.msg){ msg = resp.msg } else{msg = 'Inicio correcto'}
         let navegator = detectBrowserName();
  
 
         let data = {
           user_id:resp.email_exist.id,
           msg:msg,
           petition:'POST',
           detail:'Acceso exitoso',
           register_id:resp.email_exist.id,
           navegator,
           is_login:'1',
           ip_origin:publicip.ip,
           disconnection:'0',
           ipv6:geoip.version,
           ip:geoip.ip,
           network:geoip.network,
           city:geoip.city,
           latitude:geoip.latitude,
           longitude:geoip.longitude,
           region:geoip.region,
           timezone:geoip.timezone,
           postal:geoip.postal,
           status_id:1
         }

     
         this.eventosService.bitacora.fechaHoraEvento = moment()
         this.eventosService.bitacora.usuarioresponsable = `${Decrypt(this.userService.usuario.name)} ${Decrypt(this.userService.usuario.lastname)}`;
         this.eventosService.bitacora.tiopevento = "5"
         this.eventosService.bitacora.month = moment().format('YYYY-MM')
         this.eventosService.bitacora.descripcionevento = `Inicio de sesion correcto del usuario ${Decrypt(this.userService.usuario.name)} ${Decrypt(this.userService.usuario.lastname)}`
        this.eventosService.registrarEventos()
         this.savelog(data);
         
       }else{
         setTimeout(() => {
           // this.showerror = false;
           this.error = 'Error en la contraseña y/o usuario'
           this.showerror = true;
 
         }, 6000);
 
         setTimeout(() => {
           this.showerror = false;
 
         }, 10000);
 
         let navegator = detectBrowserName();
         let data = {
           user_id:'',
           msg:resp.msg,
           petition:'POST',
           detail:this.loginForm.get('email').value,
           register_id:'',
           navegator,
           is_login:0,
           ip_origin:publicip.ip,
           disconnection:0,
           ipv6:geoip.version,
           ip:geoip.ip,
           network:geoip.network,
           city:geoip.city,
           latitude:geoip.latitude,
           longitude:geoip.longitude,
           region:geoip.region,
           timezone:geoip.timezone,
           postal:geoip.postal,
           status_id:2
         }
         this.savelog(data);
         this.eventosService.bitacora.fechaHoraEvento = moment()
         this.eventosService.bitacora.usuarioresponsable = 'System';
         this.eventosService.bitacora.tiopevento = "5"
         this.eventosService.bitacora.month = moment().format('YYYY-MM')
         this.eventosService.bitacora.descripcionevento = `Registro de alarma- ${resp.msg} ${this.loginForm.get('email').value} `
        this.eventosService.registrarEventos()
         this.saveAlarma(`${resp.msg} - ${this.loginForm.get('email').value}`)
       }
       },err =>{
         // console.log(err);
       });
       setTimeout(() => {
         this.isLoading = false;
         this.button = 'Iniciar Sesión';
       }, 5000)
   }
 
   savelog(resep){
   this.logService.postLogs(resep);
   }
 
 
   toggleFieldTextType() {
     this.fieldTextType = !this.fieldTextType;
   }
 
 async validSession(){
   return new Promise((resolve,reject) =>{
     this.userService.validSesion().subscribe((resp:any) => {
 
      if(resp.ok){
 
       resolve(resp)
 
     }else{
 
       resolve(resp)
 
     }
 
     });
   });
 }
 getSettings(){
     this.cargando = true;
     this.settingsService.getSettingsImageLogo().then((resp:any) =>{
    //  console.log(resp);
     this.logo_img = resp.imag;
     this.session = resp.session;
     setTimeout(() => {
       this.cargando = false;
     }, 5000);
 
 })
 }

 getUbicaciones() {
  this.settingsService.getUbicaciones().subscribe((resp: any) => {
    this.ubicaciones = resp;
  });
}

 saveAlarma(description){
   let dt = moment(new Date())
   let body = {
     fechaHoraEvento:dt.format('yyyy-MM-DDThh:mm:ss'),
     usuarioresponsable:'System',
     tiopevento:20,
     month:dt.format('yyyy-MM'),
     descripcionevento:description,
     identificacioncomponentealarma:'Rechazos de inicio de sesión',
     type:'alarmas',
   } 
   // console.log(body);
   this.eventosService.alrmaInicio(body).subscribe(resp =>{});
 }

 onSelectionChange($event) {
  localStorage.removeItem('table');
  console.log($event.target);
  let data = this.loginForm.value.ubicacion;
  console.log(data);
  
  localStorage.setItem('table', data);
}
 }
 