import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../models/auth.models';
import { environment } from 'src/environments/environment';
import { LoginForm } from '../models/login-form.interface';
import { tap, map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { Usuario } from '../models/usuario.model';
import { LogsService } from './logs.service';
import  { detectBrowserName,Decrypt,Encrypt }  from './../../pages/functions/functions';
import { EventosService } from 'src/app/core/services/eventos.service';
import * as moment from 'moment';
const url = environment.api;


@Injectable({ providedIn: 'root' })
export class UserProfileService {
  public usuario: Usuario;
  public public_ip:any;
  public geoip_any;
    constructor(private http: HttpClient,private logsService:LogsService, private eventosService:EventosService) { }

    get token(): string {
      return localStorage.getItem('token') || '';
    }
    get uid():string {
      return this.usuario.id || '';
    }
    get table():string{
      return localStorage.getItem('table') || '';
    }

    get publicip(){
      return new Promise ((resolve,reject) =>{
        this.http.get(`https://api.ipify.org/?format=json`).subscribe((resp:any)=>{
          resolve(resp);
        });
      })
    }
    get geoip(){
      return  new Promise((resolve,reject)=>{
        this.http.get(`https://ipapi.co/json/`).subscribe((resp:any) =>{
          resolve(resp);
          });
      }) 
    }

    get headers() {
      return {
        headers: {
          'x-token': this.token
        }
      }
    }

    getAll() {
      let body = {
        detail:'consulta de usuario',
        msg:'',
        user_id:this.uid,
        register_id:'',
        consult:'',
        type:'GET',
        created_at:new Date(),
        petition:'GET',
        navegator:detectBrowserName(),
        is_login:null
      }
        this.logsService.postLogs(body);

        return this.http.get<User[]>(`/api/login`,this.headers);
    }

    register(user: User) {

      let body = {
        detail:'Registro de usuario',
        msg:'',
        user_id:this.uid,
        register_id:'',
        consult:'',
        type:'POST',
        created_at:new Date(),
        petition:'POST',
        navegator:detectBrowserName(),
        is_login:null
      }
        this.logsService.postLogs(body);
        this.eventosService.bitacora.fechaHoraEvento = moment()
        this.eventosService.bitacora.usuarioresponsable = `${Decrypt(this.usuario.name)} ${Decrypt(this.usuario.lastname)}`;
        this.eventosService.bitacora.tiopevento = "1"
        this.eventosService.bitacora.month = moment().format('YYYY-MM')
        this.eventosService.bitacora.descripcionevento = `Alta de usuario ${Decrypt(this.usuario.name)} ${Decrypt(this.usuario.lastname)}`
       this.eventosService.registrarEventos()
        return this.http.post(`/users/register`, user,this.headers);
    }

    validarToken(): Observable<boolean> {
      return this.http.get(`${ url }login/renew`, {
        headers: {
          'x-token': this.token
        }
      }).pipe(
        map( (resp: any) => {

          const { avatar = '', created_at, email, id, is_active , lastname,name,password,type_id,username,profile} = resp.usuario;
          this.usuario = new Usuario( email,created_at,is_active,lastname,name,password,type_id,username,profile,avatar,id);

          this.guardarLocalStorage(resp.token,resp.menu,profile);

          return true;
        }),
        catchError( error => of(false) )
      );


      }


      getUsuarios(){
        const base_url = url;
        let body = {
          detail:'Consulta de usuarios',
          msg:'Realizo una consulta de usuarios',
          user_id:this.uid,
          register_id:null,
          consult:null,
          petition:'GET',
          navegator:detectBrowserName(),
          is_login:null
        }
          this.logsService.postLogs(body);
        return this.http.get(`${url}usuarios`,this.headers);


      }

      guardarUsuario(formdata:any){
        let body = {
          detail:'Registro de usuario',
          msg:'',
          user_id:this.uid,
          register_id:'',
          consult:'',
          type:'POST',
          created_at:new Date(),
          petition:'POST',
          navegator:detectBrowserName(),
          is_login:null
        }
          this.logsService.postLogs(body);
        return this.http.post(`${url}usuarios`,formdata,this.headers);
      }


      getUser(id){
        let body = {
          detail:'consulta de usuario',
          msg:`Realizo una consulta del usuario con id = ${id}`,
          user_id:this.uid,
          register_id:'',
          consult:'',
          created_at:new Date(),
          petition:'GET',
          navegator:detectBrowserName(),
          is_login:null
        }
          this.logsService.postLogs(body);
        const base_url = `${url}usuarios/${id}`;
        return this.http.get(base_url,this.headers);
      }

      updateUser(user_selecc:any ,data:any){

        let body = {
          detail:'Actualizacion de usuario',
          msg:`Realizo una actualización del usuario - ${Decrypt(user_selecc.name)}`,
          user_id:this.uid,
          register_id:'',
          consult:'',
          created_at:new Date(),
          petition:'UPDATE',
          navegator:detectBrowserName(),
          is_login:null
        }
          this.logsService.postLogs(body);
        const base_url = `${url}usuario/${user_selecc.id}`;
        return this.http.put(base_url,data,this.headers);
      }

      desactivarUsuario(user:any){
        
        let body = {
          detail:'Desactivar  usuario',
          msg:`usuario desactivado ${Decrypt(user.name)}`,
          user_id:this.uid,
          register_id:`${user.id}`,
          consult:'',
          created_at:new Date(),
          petition:'PUT',
          navegator:detectBrowserName(),
          is_login:null
        }
          this.logsService.postLogs(body);
          this.eventosService.bitacora.fechaHoraEvento = moment()
          this.eventosService.bitacora.usuarioresponsable = `${Decrypt(this.usuario.name)} ${Decrypt(this.usuario.lastname)}`;
          this.eventosService.bitacora.tiopevento = "1"
          this.eventosService.bitacora.month = moment().format('YYYY-MM')
          this.eventosService.bitacora.descripcionevento = `Baja de usuario ${Decrypt(this.usuario.name)} ${Decrypt(this.usuario.lastname)}`
         this.eventosService.registrarEventos()
        const base_url = `${url}usuarios/desactivar/${user.id}`;
        return this.http.get(base_url,this.headers);
      }

      activarUsuario(user:any){
        let body = {
          detail:'Activar  usuario',
          msg:`usuario activado${Decrypt(user.name)}`,
          user_id:this.uid,
          register_id:`${user.id}`,
          consult:'',
          created_at:new Date(),
          petition:'PUT',
          navegator:detectBrowserName(),
          is_login:null
        }
          this.logsService.postLogs(body);
          this.eventosService.bitacora.fechaHoraEvento = moment()
          this.eventosService.bitacora.usuarioresponsable = `${Decrypt(this.usuario.name)} ${Decrypt(this.usuario.lastname)}`;
          this.eventosService.bitacora.tiopevento = "1"
          this.eventosService.bitacora.month = moment().format('YYYY-MM')
          this.eventosService.bitacora.descripcionevento = `Incorporacion de usuario ${Decrypt(this.usuario.name)} ${Decrypt(this.usuario.lastname)}`
         this.eventosService.registrarEventos()
        const base_url = `${url}usuarios/activar/${user.id}`;
        return this.http.get(base_url,this.headers);
      }
      getUsuariosActive(){
        const base_url = `${url}usuarios_actives`;
        return this.http.get(base_url,this.headers);
      }

      login( formData: LoginForm ) {

        return this.http.post(`${ url }login/${this.table}`, formData ,this.headers)
                    .pipe(
                      tap( (resp: any) => {
                        if(resp.ok){
                          if(resp.email_exist.is_new){

                          }else{
              
                            this.guardarLocalStorage( resp.token,resp.menu,resp.email_exist.profile);
                          }
                        }



                      }));
      }

      guardarLocalStorage( token: string,menu:any,profile:any) {

        localStorage.setItem('token', token );
        localStorage.setItem('menu', JSON.stringify(menu) );
        localStorage.setItem('profile', profile );
    
      }

      guardarLocalStorageTime( avaible: string,time_execute:any) {

        localStorage.setItem('avaible', avaible );
        localStorage.setItem('time_execute', time_execute);
      }

      actualizarPerfil(data:any){
       
        let body = {
          detail:'Actualizacion de perfil',
          msg:`usuario actualizo su perfil ${data.nombre}`,
          user_id:this.uid,
          register_id:`${data.id}`,
          consult:'',
          created_at:new Date(),
          petition:'PUT',
          navegator:detectBrowserName(),
          is_login:null
        }
          this.logsService.postLogs(body);
      return this.http.put(`${ url }usuario_update/${ data.id }`, data ,this.headers);
      }


      actualizarPassword(data:any){
        let body = {
          detail:'Cambio de contraseña',
          msg:`usuario  ${data.nombre} cambio su contraseña`,
          user_id:this.uid,
          register_id:`${data.id}`,
          consult:'',
          created_at:new Date(),
          petition:'PUT',
          navegator:detectBrowserName(),
          is_login:null
        }
          this.logsService.postLogs(body);
        return this.http.put(`${ url }password_update/${ data.id }`, data ,this.headers);
        }

      lastSession(id:any){
        return this.http.get(`${ url }last_session/${ id }`,this.headers);

      }

      validSesion(){
        return this.http.get(`${ url }generate_file`);
      }

        logout() {
          
          localStorage.removeItem('token');
          localStorage.removeItem('menu');
        }


        ValidToken(token:string){
          return this.http.get(`${url}auth/valid/token/${token}`);
        }

        changePassword(body:any){
          return this.http.put(`${url}usuario/change/password/${body.id}`,body);
      
        }
        generateToken(token){
          return this.http.get(`${url}generate/token`,{headers:{
            'x-token':token
          }})
        }

       
}
