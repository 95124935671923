import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

let url = environment.api;
@Injectable({
  providedIn: 'root'
})
export class EventosService {
  get token(): string {
    return localStorage.getItem('token') || '';
  }
  get table(): string {
    return localStorage.getItem('table') || '';
  }
  
  get headers() {
    return {
      headers: {
        'x-token': this.token
      }
    }
  }
  public bitacora = {
    fechaHoraEvento:null,
    usuarioresponsable:"",
    descripcionevento:"",
    month:"",
    identificacionEvento:"",
    tiopevento:"",
    type:"eventos",
    folio:"",
  };
  constructor(private http:HttpClient) { }

  
  getEventos(){
    return this.http.get(`${url}eventos`,this.headers);
  }

  postEventos(body:any){
    return this.http.post(`${url}eventos`,body,this.headers);
  }
  postEventosAlarma(body:any){
    return this.http.post(`${url}alarmas`,body,this.headers);
  }
  
  registrarEventos(){
    this.http.post(`${url}eventosAll/${this.table}`,this.bitacora).subscribe();
  }



validDateTimeEvent(fecha){
  return this.http.get(`${url}eventos/valid/${fecha}`,this.headers);

}

  putEventos(body:any){
    return this.http.put(`${url}eventos/${body.id}`,body,this.headers);
  }

  putActiveEventos(body:any){
    return this.http.get(`${url}eventos/active/${body.id}`,this.headers);
  }

  putInactiveEventos(body:any){
    return this.http.get(`${url}eventos/inactive/${body.id}`,this.headers);
  }

  getEventsAttacment(id:any){
    const base  = `${url}eventos/attachment/files/${id}`;
    return this.http.get(base,this.headers);
  }

  getDownloadfile(id:any){
    let base = `${url}eventos/download/file/doc/${id}`;
    return this.http.get(base,{headers:{'x-token': this.token}, responseType:'blob'});
  }
  alrmaInicio(body:any){
    return this.http.post(`${url}eventosAll`,body);
  }
  getEventosAlarmas(){
        
    // 
  return this.http.get(`${url}alarmas`,this.headers);
}
}
