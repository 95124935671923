<app-loader>
</app-loader>
<div class="container-fluid p-0">
    <div class="row g-0">

        <div class="col-xl-9">
            <div class="auth-full-bg pt-lg-5 p-4">

                <div class="w-100">
                    <div class="bg-overlay"></div>

                    <div class="d-flex h-100 flex-column">

                        <div class="p-4 mt-auto">
                            <div class="row justify-content-center">
                                <div class="justify-content-center">
                                    <!-- <img src="assets/images/logocompany.png" alt="" height="80" width="250"> -->
                                    <img [src]="logo_img | imagenCompany" alt="" height="100" width="280">
                                    <!-- <img src="assets/images/logolinker.png" alt="" height="80" width="250"> -->

                                </div>
                                <div class="col-lg-7">
                                    <div class="text-center">
                                        <h4 class="mb-3"><i class="bx bxs-quote-alt-left text-primary h1 align-middle me-3"></i><span class="text-primary">Controles</span> Volumetricos</h4>

                                        <div dir="ltr">
                                            <owl-carousel-o class="owl-theme auth-review-carousel" [options]="carouselOption">
                                                <ng-template carouselSlide>
                                                    <div class="item">
                                                        <div class="py-3">
                                                            <!-- <p class="font-size-16 mb-4">" Las mediciones realizadas por los controles volumétricos tienen por fin la generación de archivos de texto en un formato y periodo determinados"
                                                          </p> -->

                                                            <div>
                                                                <!-- <h4 class="font-size-16 text-primary">Abs1981</h4> -->
                                                                <!-- <p class="font-size-14 mb-0">- MBQ</p> -->
                                                            </div>
                                                        </div>

                                                    </div>
                                                </ng-template>
                                                <!-- <ng-template carouselSlide>
                                                  <div class="item">
                                                      <div class="py-3">
                                                          <p class="font-size-16 mb-4">" If Every Vendor on Envato
                                                              are
                                                              as supportive as Themesbrand, Development with be a
                                                              nice
                                                              experience. You guys are Wonderful. Keep us the good
                                                              work. "</p>

                                                          <div>
                                                              <h4 class="font-size-16 text-primary">nezerious</h4>
                                                              <p class="font-size-14 mb-0">- Skote User</p>
                                                          </div>
                                                      </div>

                                                  </div>
                                              </ng-template> -->
                                            </owl-carousel-o>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end col -->

        <div class="col-xl-3">
            <div class="auth-full-page-content p-md-5 p-4">
                <div class="w-100">

                    <div class="d-flex flex-column h-100">
                        <div class="mb-1 mb-md-5">
                            <!-- <a routerLink="/" class="d-block auth-logo"> -->
                            <img [src]="logo_img | imagenCompany" alt="" height="90" width="200" class="auth-logo-dark">
                            <!-- <img [src]="logo_img | imagenCompany" alt="" height="18" width="250" class="auth-logo-light"> -->
                            <!-- <img src="assets/images/logolinker.png" alt="" height="80" width="250"> -->
                            <!-- </a> -->

                        </div>
                        <div class="my-auto">

                            <div>
                                <h5 class="text-primary">Bienvenido de nuevo!</h5>
                                <p class="text-muted">Inicia sesión para continuar.</p>
                            </div>

                            <div class="mt-4">
                                <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
                                    <div *ngIf="showerror" class="alert alert-danger" role="alert">
                                        {{error}}
                                    </div>

                                    <div class="mb-3">
                                        <label for="formrow-inputState" class="form-label">Empresa</label>
                                        <select id="formrow-inputState" formControlName="ubicacion" class="form-select" (change)="onSelectionChange($event)">
                                            <option selected value="empty">Corporativo</option>
                                            <option *ngFor="let option of ubicaciones" [value]="option.folio_table">
                                                {{option.name}}
                                            </option>
                                        </select>
                                     
                                      </div>
                                    <div class="mb-3">
                                        <label for="email">Email</label>
                                        <input type="email" class="form-control" id="email" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Ingresa email">
                                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                            <div *ngIf="f.email.errors.required">Email es requerido</div>
                                            <div *ngIf="f.email.errors.email">Email debe ser valido
                                            </div>
                                        </div>
                                    </div>

                                    
                                    <div class="mb-3">
                                        <!-- <div class="float-end">
                                            <a class="text-muted">Olvide mi
                                              password?</a>
                                        </div> -->
                                        <label for="userpassword">Password</label>
                                        <div class="input-group auth-pass-inputgroup">
                                            <input [type]="fieldTextType ? 'text' : 'password'" class="form-control" maxlength="50" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Ingresa password" aria-label="Password" aria-describedby="password-addon">
                                            <button class="btn btn-light" (click)="toggleFieldTextType()" type="button" id="password-addon"><i
                                                  class="mdi mdi-eye-outline"></i></button>
                                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                                <span *ngIf="f.password.errors.required">Password is required</span>
                                            </div>
                                        </div>
                                    </div>
                                    <!--
                                  <div class="form-check">
                                      <input class="form-check-input" type="checkbox" id="remember-check">
                                      <label class="form-check-label" for="remember-check">
                                          Remember me
                                      </label>
                                  </div> -->

                                    <div class="mt-3 d-grid">
                                        <button class="btn btn-primary btn-block" [disabled]="isLoading" type="submit"><i class="fa" [ngClass]="{'fa-spin fa-spinner': isLoading, 'fa-check': !isLoading}"></i> {{button}}</button>

                                    </div>


                                    <!-- <div class="mt-4 text-center"> -->
                                    <!-- <h5 class="font-size-14 mb-3">Sign in with</h5> -->
                                    <!-- <ul class="list-inline">
                                          <li class="list-inline-item">
                                              <a href="javascript::void()"
                                                  class="social-list-item bg-primary text-white border-primary">
                                                  <i class="mdi mdi-facebook"></i>
                                              </a>
                                          </li>
                                          <li class="list-inline-item">
                                              <a href="javascript::void()"
                                                  class="social-list-item bg-info text-white border-info">
                                                  <i class="mdi mdi-twitter"></i>
                                              </a>
                                          </li>
                                          <li class="list-inline-item">
                                              <a href="javascript::void()"
                                                  class="social-list-item bg-danger text-white border-danger">
                                                  <i class="mdi mdi-google"></i>
                                              </a>
                                          </li>
                                      </ul> -->
                                    <!-- </div> -->

                                </form>
                                <!-- <div class="mt-5 text-center">
                                  <p>Don't have an account ? <a routerLink="/account/signup-2"
                                          class="fw-medium text-primary"> Signup now </a> </p>
                              </div> -->
                            </div>
                        </div>

                        <div class="mt-4 mt-md-5 text-center">
                            <!-- <p class="mb-0">© {{year}} LINKËR Technology.</p> -->
                            <img src="assets/images/volumetricos.png" height="50" width="120">
                            <p class="mb-0">© {{year}} MBQ. eSCV 1.2.1</p>
                            <!-- LINKËR Technology -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end col -->
    </div>
    <!-- end row -->
</div>
<!-- end container-fluid -->