import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';



const base_url = environment.api;

@Injectable({
  providedIn: 'root'
})
export class LogsService {

  constructor(private http:HttpClient) { }
  get token(): string {
    return localStorage.getItem('token') || '';
  }
  get table(): string {
    return localStorage.getItem('table') || '';
  }

  get headers() {
    return {
      headers: {
        'x-token': this.token
      }
    }
  }

  postLogs(log:any){
    let base = `${base_url}logs/${this.table}`;
    return this.http.post(base,log).subscribe();
  }

}
