import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';


import { CookieService } from 'ngx-cookie-service';

import { TranslateService } from '@ngx-translate/core';
import { Usuario } from '../../core/models/usuario.model';

import { Decrypt,detectBrowserName,Encrypt } from '../../pages/functions/functions';
import { LanguageService } from 'src/app/core/services/language.service';
import { UserProfileService } from 'src/app/core/services/user.service';
import { SettingsService } from 'src/app/core/services/settings.service';
import { AuthenticationService } from 'src/app/core/services/auth.service';
import { AuthfakeauthenticationService } from 'src/app/core/services/authfake.service';
import { LogsService } from 'src/app/core/services/logs.service';
import { NotificationsService } from '../../core/services/notifications.service';



@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})

/**
 * Topbar component
 */
export class TopbarComponent implements OnInit {

  element;
  cookieValue;
  flagvalue;
  countryName;
  valueset;
  public usuario: Usuario;
  name;
  logo_img;
  loading:boolean = true;
  notificaciones:any = [];
  table:string = localStorage.getItem('table');
    constructor(@Inject(DOCUMENT) private document: any, private router: Router, 
              private authService: AuthenticationService,
              private authFackservice: AuthfakeauthenticationService,
              public languageService: LanguageService,
              public translate: TranslateService,
              public _cookiesService: CookieService,
              private usuarioService: UserProfileService,
              private settingsService:SettingsService,
              private  logService:LogsService,
              private notificationsService:NotificationsService) {
                this.usuario = usuarioService.usuario;
  }

  listLang = [
    { text: 'Español', flag: 'assets/images/flags/spain.jpg', lang: 'es' }
    // { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' }
  ];

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  ngOnInit() {
    this.openMobileMenu = false;
    this.element = document.documentElement;

    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) { this.valueset = 'assets/images/flags/es.jpg'; }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }

    this.getSettings();
    this.getNotifications();
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  getNotifications(){
    this.notificationsService.getNotifications().subscribe((resp:any) =>{
      this.notificaciones = resp.sort(function(a, b) {
        return b.created_at.localeCompare(a.created_at)
    });
    })
  }


  
  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }


  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }


  lockScreen(){
    this.router.navigate(['/pages/lock-screen-2'])
  }


  /**
   * Logout the user
   */
  async logout() {
    let geoip:any =  await this.usuarioService.geoip;
    let publicip:any = await this.usuarioService.publicip;
    this.usuarioService.logout();
    let navegator = detectBrowserName();
 

    let data = {
      user_id:this.usuarioService.uid,
      msg:'Cierre de usuario',
      petition:'POST',
      detail:'Cierre de Sesión',
      register_id:this.usuarioService.uid,
      navegator,
      is_login:'0',
      ip_origin:publicip.ip,
      disconnection:'1',
      ipv6:geoip.version,
      ip:geoip.ip,
      network:geoip.network,
      city:geoip.city,
      latitude:geoip.latitude,
      longitude:geoip.longitude,
      region:geoip.region,
      timezone:geoip.timezone,
      postal:geoip.postal,
      status_id:2
    }
    
    this.savelog(data);
    this.router.navigate(['/account/login']);
    localStorage.setItem('rout',Encrypt('/account/login'));
  }

  savelog(resep){
    this.logService.postLogs(resep);
    }
  /**
   * Fullscreen method
   */
  fullscreen() {
    document.body.classList.toggle('fullscreen-enable');
    if (
      !document.fullscreenElement && !this.element.mozFullScreenElement &&
      !this.element.webkitFullscreenElement) {
      if (this.element.requestFullscreen) {
        this.element.requestFullscreen();
      } else if (this.element.mozRequestFullScreen) {
        /* Firefox */
        this.element.mozRequestFullScreen();
      } else if (this.element.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        this.element.webkitRequestFullscreen();
      } else if (this.element.msRequestFullscreen) {
        /* IE/Edge */
        this.element.msRequestFullscreen();
      }
    } else {
      if (this.document.exitFullscreen) {
        this.document.exitFullscreen();
      } else if (this.document.mozCancelFullScreen) {
        /* Firefox */
        this.document.mozCancelFullScreen();
      } else if (this.document.webkitExitFullscreen) {
        /* Chrome, Safari and Opera */
        this.document.webkitExitFullscreen();
      } else if (this.document.msExitFullscreen) {
        /* IE/Edge */
        this.document.msExitFullscreen();
      }
    }
  }

  getSettings(){
    this.loading = true;
    this.settingsService.getSettings().then((resp:any) =>{
   let data = [] 
   data.push(...resp)
    
   let resultado = data.filter(data => data.key_value === 'logo_company') 
     
   this.logo_img = resultado[0].value;
  this.loading = false;
    })
  }
}
