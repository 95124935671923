
import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  production: true,
  defaultauth: 'fackbackend',
  firebaseConfig: {
    apiKey: '',
    authDomain: '',
    databaseURL: '',
    projectId: '',
    storageBucket: '',
    messagingSenderId: '',
    appId: '',
    measurementId: '',
  },
  //  api:'https://40.80.212.178/api/',
  // api:'http://10.1.0.7/api/',
  // api:'https://qualtia.scvplatform.com/api/',
  // api:'http://localhost:3000/api/',
  
  api:'https://bydsa.scvplatform.com/api/',
  
  
    // api:'http://localhost/api/api/',
  // api:'https://vcontroltfcm.mx/apirest/api/',
  // api:'https://gimsa.scvplatform.com/api/api/',
  // api:'https://libbey.scvplatform.com/api/api/',
  // api:'https://www.mbqdatacloud.com/api_demo/api/',
  // api:'https://mbqdatacloud.com/api_volumetricosmbq/api/',


  idleTimeInMinutes: 5,
  logLevel: NgxLoggerLevel.OFF,
  serverLogLevel: NgxLoggerLevel.ERROR,
  

};
